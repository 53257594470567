<template>
  <div
    v-if="cityData.htmlBlock"
    class="component--html-block"
  >
    <div v-html="cityData.htmlBlock"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("data", ["cityData"]),
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--html-block {
  @media screen and (min-width: $width) {
    width: 70%;
    margin: auto;
  }
}
</style>