<template>
  <div class="component--entry-form">
    <h2>ОСТАВЬТЕ ЗАЯВКУ</h2>
    <p>и наш специалист свяжется с Вами</p>
    <form ref="form">
      <input :class="{error: errors.name}" name="name" type="text" placeholder="ИМЯ" id="main_name">
      <input :class="{error: errors.phone}" name="phone" type="text" placeholder="ТЕЛЕФОН" id="main_telephone">
      <!-- <input :class="{error: errors.email}" name="email" type="text" placeholder="E-MAIL" id="main_email"> -->
      <input v-show="false" :value="cityData.name" name="city" type="text" id="main_city">
      <select v-if="typeIsVisible" name="type">
        <option
          v-for="(product, i) in cityData.catalog"
          :key="i"
          :value="product.title"
          :selected="product.title === productTypeSelected"
        >
          {{ product.title }}
        </option>
      </select>
      <input type="submit" value="ОТПРАВИТЬ" class="submit" @click.prevent="submit()">
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      errors: {
        name: false,
        phone: false,
        email: false,
      }
    }
  },
  computed: {
    ...mapGetters("data", ["cityData", "productTypeSelected"])
  },
  props: {
    typeIsVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions("app", ["sendMessage"]),
    submit() {
      const form = this.$refs.form;
      const formData = new FormData(form);
      const phone = formData.get('phone');

      const nameError = !formData.get('name').length
      let phoneError = phone.length < 11 || phone.length > 12

      if (phone.length === 11) {
        phoneError = !(Array.from(phone)[0] == 8)
      } else if (phone.length === 12) {
        phoneError = !phone.includes('+7')
      }
      // const email = formData.get('email');
      // const emailError = !email.length;
      const emailError = false;

      this.errors.name = nameError
      this.errors.phone = phoneError
      this.errors.email = emailError

      if (!nameError && !phoneError && !emailError) {
        this.sendMessage(formData).then(() => form.reset())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--entry-form {
  background: url(../assets/img/points/form_back.png) no-repeat;

  height: 380px;
  @media screen and (max-width: $width) {
    background-size: 100% 100%;
    width: 100%;
  }
  @media screen and (min-width: $width) {
    background-size: 100%;
    width: 500px;
  }
  h2, p {
    padding: 0;
    text-align: center;
    color: #31343e;
    text-shadow: 0px 1.3px 1px white;
  }
  h2 {
    margin: 0px;
    padding-top: 18px;
    font-size: 30px;
  }
  p {
    font-size: 17px;
    margin-top: -4px;
  }
  form {
    @media screen and (max-width: $width) {
      transform: scale(0.9);
    }
    input, select {
      @media screen and (max-width: $width) {
        width: 90%;
      }
      @media screen and (min-width: $width) {
        width: 348px;
      }
    }
    input {
      height: 40px;
      background: url(../assets/img/points/form_input.png) no-repeat center;
      text-align: center;
      border: none;
      margin: 0 0 5px;
      font: 18px PFDinTextCondPro;
      &.error {
        background: #ff4a4a;
      }
    }
    select {
      color: #75758a;
      font-size: 10px;
      display: block;
      height: 40px;
      background: url(../assets/img/points/form_input.png) no-repeat center;
      text-align: center;
      border: none;
      margin: 0 auto 5px;
      font: 18px PFDinTextCondPro;
      text-transform: uppercase;
      option {
        text-transform: uppercase;
        &:focus {
          background: #ADE1F5;
        }
      }
    }
    .submit {
      display: block;
      height: 51px;
      background: url(../assets/img/points/form_button.png) no-repeat center;
      color: #fff;
      font-size: 19px;
      font-weight: 500;
      letter-spacing: 1px;
      margin: auto;
      cursor: pointer;
      @media screen and (max-width: $width) {
        width: 90%;
      }
      @media screen and (min-width: $width) {
        width: 344px;
      }
    }
  }
}
</style>