<template>
  <section v-if="cityData.partners" class="partners">
    <div class="container">
        <h2 class="partners-title">{{ cityData.partners.title }}</h2>
        <div class="/img/partners-logos">
            <img v-for="(img, i) in cityData.partners.img" :key="i" :src="`/img/${img}`">
        </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("data", ["cityData"])
  }
}
</script>

<style lang="scss" scoped>
$width: 1324px;
.component--main-block-8 {
  margin: auto;
  @media screen and (max-width: $width) {
    width: 95%;
  }
  @media screen and (min-width: $width) {
    width: 1010px;
    padding-top: 80px;
  }
}
.partners {
    text-align: center;
    padding: 40px 0;
}

.partners-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.partners-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.partners-logos img {
    max-width: 150px;
    height: auto;
}
</style>