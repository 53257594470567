<template>
  <div>
    <Header />
    <MainBlock1 />
    <MainBlock2 />
    <HtmlBlock />
    <Documents />
    <Catalog />
    <MainBlockContactForm />
    <MainBlock6 />
    <MainBlock7 />
    <Reviews />
    <ContactForm
    title="Хотите воспользоваться нашими  услугами или возникли вопросы?"
    subtitle="Оставьте ваши контактные данные и мы свяжемся с Вами в ближайшее время."
    :ps="contactFormPs"
    />
    <MainBlock8 />
    <Footer />

    <CirclePhone />
    <CityAlert />
    <CitySelectPopup />
    <OrderFormPopup />
    <OrderAccepted />
  </div>
</template>

<script>
import Header from '@/components/Header'
import MainBlock1 from '@/components/MainBlock1'
import MainBlock2 from '@/components/MainBlock2'
import HtmlBlock from '@/components/HtmlBlock'
import Documents from '@/components/Documents'
import Catalog from '@/components/Catalog'
import MainBlockContactForm from '@/components/MainBlockContactForm'
import MainBlock6 from '@/components/MainBlock6'
import MainBlock7 from '@/components/MainBlock7'
import MainBlock8 from '@/components/MainBlock8'
import Reviews from '@/components/Reviews'
import ContactForm from '@/components/ContactForm'
import Footer from '@/components/Footer'
import CirclePhone from '@/components/CirclePhone'
import CityAlert from '@/components/CityAlert'
import CitySelectPopup from '@/components/CitySelectPopup'
import OrderFormPopup from '@/components/OrderFormPopup'
import OrderAccepted from '@/components/OrderAccepted'
export default {
  name: 'Home',
  components: {
    Header,
    MainBlock1,
    MainBlock2,
    HtmlBlock,
    Documents,
    Catalog,
    MainBlockContactForm,
    MainBlock6,
    MainBlock7,
    MainBlock8,
    Reviews,
    ContactForm,
    Footer,
    CirclePhone,
    CityAlert,
    CitySelectPopup,
    OrderFormPopup,
    OrderAccepted
  },
  data() {
    return {
      contactFormPs: `<h4 style="color:rgb(98, 165, 226);font-weight: normal;margin-bottom: 0px;">В целях улучшения качества предоставляемых услуг, вы можете обратиться непосредственно к<br><span style="color:#ffffff;">&nbsp;Директору:Алиманову Ренату Сачиковичу&nbsp;по телефону:<a href="javascript:window.location='tel:89616805514';" style="color: white;text-decoration: none;"> +7 961-680-55-14</a></span></h4>`
    }
  }
}
</script>

<style>

</style>