
export default {
  namespaced: true,
  state: {
    cityData: {},
    cityDefault: null,
    citySelected: null,
    citySelectIsVisible: false,
    orderFormIsVisible: false,
    cityAlertIsVisible: true,
    orderAcceptedIsVisible: false,
    timerMin: 0,
    startTime: null,
    productTypeSelected: null
  },
  mutations: {
    init(state, data) {
      state.cityData = data.city;
      state.timerMin = data.timerMin;
      state.cityDefault = data.cityDefault

      let startTime = sessionStorage.getItem('startTime');

      if (startTime === null) {
        startTime = new Date();
        sessionStorage.setItem('startTime', startTime);
      }

      state.startTime = new Date(startTime);
    },
    citySelect(state, cityId) {
      state.citySelected = cityId
    },
    citySelectIsVisibleSet(state, isVisible) {
      state.citySelectIsVisible = isVisible
    },
    orderFormIsVisibleSet(state, isVisible) {
      state.orderFormIsVisible = isVisible
    },
    cityAlertIsVisibleSet(state, isVisible) {
      state.cityAlertIsVisible = isVisible
    },
    orderAcceptedIsVisibleSet(state, isVisible) {
      state.orderAcceptedIsVisible = isVisible
    },
    productTypeSelectedSet(state, productTitle) {
      state.productTypeSelected = productTitle
    }
  },
  actions: {
    init({ commit }, data) {
      commit('init', data)
    },
    citySelect({ commit, getters }, cityId) {
      commit('citySelect', cityId)
      document.title = getters.cityData.title || getters.cityData.subtitle || ''
      document.querySelector('head meta[name="description"]').setAttribute('content', getters.cityData.description || '')
    },
    citySelectIsVisibleSet({ commit }, isVisible) {
      commit('citySelectIsVisibleSet', isVisible)
    },
    orderFormIsVisibleSet({ commit }, isVisible) {
      commit('orderFormIsVisibleSet', isVisible)
    },
    cityAlertIsVisibleSet({ commit }, isVisible) {
      commit('cityAlertIsVisibleSet', isVisible)
    },
    orderAcceptedIsVisibleSet({ commit }, isVisible) {
      commit('orderAcceptedIsVisibleSet', isVisible)
    },
    productTypeSelectedSet({ commit }, productTitle) {
      commit('productTypeSelectedSet', productTitle)
    }
  },
  getters: {
    citySelected: state => state.citySelected,
    cityDefault: state => state.cityDefault,
    cityDataAll: state => state.cityData,
    cityData: (state, getters) => {
      return {
        id: getters.citySelected,
        ...getters.cityDataAll?.[getters.citySelected]
      }
    },
    citySelectIsVisible: state => state.citySelectIsVisible,
    orderFormIsVisible: state => state.orderFormIsVisible,
    cityAlertIsVisible: state => state.cityAlertIsVisible,
    orderAcceptedIsVisible: state => state.orderAcceptedIsVisible,
    productTypeSelected: state => state.productTypeSelected,
    timerMin: state => state.timerMin,
    startTime: state => state.startTime
  }
}